// TermsButton.js
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {CGTermsPopup} from './termsPopup';
import {Button} from "reactstrap";

const TermsButton = () => {
  //const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const togglePopup = (e) => {
    e.preventDefault(); // Prevents default behavior, like form submission or link navigation
   // setShowPopup(!showPopup);
    navigate('/terms-conditions-page'); // Navigate to the terms-conditions-page
  };

  return (
    <>
         <div className="text-left">
         <Button
             className="btn-neutral btn-icon ml-1"
             color="default"
             onClick={togglePopup}
           >
        <span className="btn-inner--text">View General Terms and Conditions</span>
        </Button> 
      {/*showPopup && <TermsPopup onClose={() => setShowPopup(false)} /> */}
       </div>
    </>
  );
};

const CGTermsButton = () => {

  const [cgShowPopup, setCGShowPopup] = useState(false);
 // const history = useHistory();
  
  const togglePopup = (e) => {
    e.preventDefault(); // Prevents default behavior, like form submission or link navigation
    setCGShowPopup(!cgShowPopup);
  };

  return (
    <>
         <div className="text-left">
         <Button
             className="btn-neutral btn-icon ml-1"
             color="default"
             onClick={togglePopup}
           >
        <span className="btn-inner--text">View CG Service Terms and Conditions</span>
        </Button> 
      {cgShowPopup && <CGTermsPopup onClose={() => setCGShowPopup(false)} />}
       </div>
    </>
  );
};
export {TermsButton, CGTermsButton}

