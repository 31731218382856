/******************************************************************************
* File Name: App.js
* Author   : Mukesh Kumar Basrani
* Summary  : App (entry) code
* Created  : 30-May-2024
* Modified : 16-July-2024 
******************************************************************************/

//import logo from './logo.svg';
//import logo from 'assets/img/brand/TechnoWheel_ShrtnoBk.png';
/* Top Co Cfg */
//import hostingCfg from "./hostingcfg/hostingcfg.json"

import "./App.css";
import React, { useState, useEffect } from 'react';
import {createContext, useContext } from 'react';

import { auth} from './firebaseCfg.js';

//import Auth from './components/Auth';
import Upload from './components/Upload';
import UploadZerd from './components/UploadZerd'
import {PaymentWrpCnf, PaymentWrp} from './components/PaymentWrp';
import Download from './components/Download';
import { startFirebaseUI } from './components/firebaseAuth';

import CgTermsContext, { useCgTermsContext } from './termsContext'; 

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";

//import axios from 'axios'
//const REACT_APP_HOSTING_URL=process.env.REACT_APP_HOSTING_URL;

//Create User Context
export const UserContext = createContext();
export const useUserContext = () => useContext(UserContext);

const App = () => {
  const [user, setUser] = useState(null);
//  const [username, setUsername] = useState('');
  const [file, setFile] = useState(null);
 // const [username, setUsername] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [processSuccess, setProcessSuccess] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);
  const [processedFileUrl, setProcessedFileUrl] = useState('');
  const [invoiceFileUrl, setInvoiceFileUrl] = useState('');
  const [paymentCnfSuccess, setPaymentCnfSuccess] = useState('')
  const { cgTermsAccepted, genTermsAccepted } = useCgTermsContext(); // Access context
  const [selectedPlatform, setSelectedPlatform] = useState(null); // Track selected platform

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
    setUser(user);
    });
  }, []);
  
  useEffect(() => {
  /*  
    if (process.env.NODE_ENV === 'development') {
      console.log('Running in development mode');
    } else {
      console.log('Running in production mode');
    }

    console.log(" useEffect 67  " ,process.env.REACT_APP_HOSTING_URL);
    console.log(" useEffect 74  " ,{auth}, {REACT_APP_HOSTING_URL},process.env.REACT_APP_HOSTING_URL_ALL_CORS);
    console.log(" useEffect 75  " ,process.env.REACT_APP_RZP_INIT_URL, process.env.REACT_APP_RZP_VERIFY_URL);
  */
    startFirebaseUI('#firebaseui-auth-container');
  }, []);   

/********* Login Register ends *******************/

 /* const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  }; */

  const userContextValue = {
    user,
    file,
    setFile,
    uploadSuccess,
    setUploadSuccess,
    processSuccess,
    setProcessSuccess,
    paymentSuccess,
    setPaymentSuccess,
    downloadSuccess,
    setDownloadSuccess,
    processedFileUrl,
    setProcessedFileUrl,
    invoiceFileUrl, 
    setInvoiceFileUrl,
    paymentCnfSuccess,
    setPaymentCnfSuccess,
    cgTermsAccepted,
    genTermsAccepted,
    selectedPlatform,
    setSelectedPlatform,
    
  };
  
 /* Small Test
 return (
  <UserContext.Provider value={userContextValue}> 
  <div>
   { <Upload     />}
  </div>
  </UserContext.Provider>    
        );
 */
/******* New With Login/Register Render ************/
/*
return (
  <UserContext.Provider value={userContextValue}>
 <div>
 { user ? (
                    <PaymentWrp />
           ) : (
        <div id="firebaseui-auth-container"></div>

      )}
    </div>
  </UserContext.Provider>
);
}; */
return (
  <UserContext.Provider value={userContextValue}>
    <div>
      {user ? (
        <>
          <h2>Welcome {user.displayName} {user.email}</h2>
         {/* Platform Selection */}
            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <button 
              style={{ backgroundColor: 'blue', color: 'white', marginRight: '30px' }} 
              onClick={() => setSelectedPlatform('ICICI')}>ICICI</button> 
            <button 
              style={{ backgroundColor: 'green', color: 'white' }} 
              onClick={() => setSelectedPlatform('Zerodha')}>Zerodha</button>
          </div>
          {/* Render components based on selected platform */}
          {selectedPlatform === 'ICICI' && (
            <>
              <h3>Currently supporting ICICI DIRECT Equity Gain only Download Gain statement in csv format <br/> from ICICI direct -> Portfolio -> Capital Gain --> Equity </h3>
              <div>
                {paymentCnfSuccess && !downloadSuccess && (
                  <Download />
                )}
                {paymentSuccess && !paymentCnfSuccess && (
                  <PaymentWrpCnf />
                )}
                {processSuccess && !paymentSuccess && (
                  <PaymentWrp />
                )}
                {uploadSuccess && !processSuccess && (
                  <Upload />
                )}
                {!uploadSuccess && (
                  <Upload />
                )}
              </div>
            </>
          )}

          {selectedPlatform === 'Zerodha' && (
            <>
              <h3>Currently supporting Zerodha Equity Gain only Download P&L statement in excel format <br/> from Zerodha --> Tax P&L </h3>
              <div>
                {paymentCnfSuccess && !downloadSuccess && (
                  <Download />
                )}
                {paymentSuccess && !paymentCnfSuccess && (
                  <PaymentWrpCnf />
                )}
                {processSuccess && !paymentSuccess && (
                  <PaymentWrp />
                )}
                {uploadSuccess && !processSuccess && (
                  <UploadZerd />
                )}
                {!uploadSuccess && (
                  <UploadZerd />
                )}
              </div>
            </>
          )}
        </>
      ) : (
        <div id="firebaseui-auth-container"></div>
      )}
    </div>
  </UserContext.Provider>
);
};

export default App;

