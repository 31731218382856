/*!

*/

import React , {useState} from "react";
import classnames from "classnames";
import {getAuth } from "firebase/auth"
import { httpsCallable, getFunctions } from "firebase/functions";



// reactstrap ../../components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Badge
} from "reactstrap";

import { Link } from "react-router-dom";

// core ../../components
import DemoNavbar from "../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../components/Footers/SimpleFooter.js";
import CardsFooter from "../components/Footers/CardsFooter.js";
import axios from 'axios'; // Import Axios for making HTTP requests

import hostingCfg from "../hostingcfg/hostingcfg.json"
/*  ---- Move to Components later --------- */
const REACT_APP_PRJQUERY_BEPY_URL = process.env.REACT_APP_PRJQUERY_BEPY_URL
const functions = getFunctions();

class DesignServices extends React.Component {
constructor(props) {
super(props);
this.state = {
    name:'',
    email: '',
    message: '',
    isPrjClk:false
  };

 }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;


  }



  
    /*  ---- Move to Components later --------- */
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };   
    

    handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message, isPrjClk } = this.state;
    if(this.state.isPrjClk)
    {
      alert(' You have already submitted , Restart fresh for new requirement');
      return;
    }
    
    const auth = getAuth();
    this.setState({isPrjClk:true});
//    console.log('Auth = ', {auth})
    if(auth && auth.currentUser)
    {
        //console.log('currentUser = ', {auth.currentUser})
        try {
            console.log('Response from BE:', name, email, message);
            //console.log("project url ",REACT_APP_PRJQUERY_BEPY_URL)
    /*      const response = await axios.post('https://your-backend-api-url.com/userData', {
            name,
            email,
            message
          }); */
          //console.log('Response from BE:', response.data);
          // Optionally handle success or navigate to another page
        } catch (error) {
          console.error('Error sending data:', error);
          // Optionally handle error
        }
   /*  ---- Move to Components later --------- */
    // Call Cloud Function to process the file
    const projectQuery = httpsCallable(functions, 'projectQuery', { method: 'POST' });
    const messageWithHeader="DesignArchService"+message;
    console.log("messageWithHeader", messageWithHeader);
    const data = { name: name, email: email, message:messageWithHeader };
    
    projectQuery(data)
      .then((result) => {
        console.log('Project Query result=', { result });
        if (result.data.success === true) {
          //setProcessSuccess(true);
          alert('Thank you for submitting your requirement, We will process and contact back on the email');
        } else {
          alert('Failed to process your requirement');
        }
        // setProcessedFileUrl(result.data.download_link);
      })
      .catch((error) => {
        console.error('Error processing message:', error);
        if (error.error === 'Failed to process Message') {
          alert('Failed to process Message');
        } else {
          alert('An unexpected error occurred.Ensure you are logged in. Try logging in with google else try later');
        }
      })
      .finally(() => {
        
      });
    /*  ---- Move to Components later --------- */    
    }
    else
        alert('You are not signed in to send Information, Register/Login first using buttons in top');
  };

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-teal">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
              <Row className="justify-content-center">
              <h3 className="text-secondary"> Our Expertise </h3> 
              </Row>
          </section>
          {/* Right Align */}
          <section className="section section-lg pt-lg-22 mt--200">
            <Container>
              <Row className="justify-content-center mt-5"> {/* Adds top margin for spacing */}
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="3" md="6" sm="12"> {/* Each card takes 3/12 of the row width */}
                      <Card className="card-lift--hover shadow border-0 d-flex flex-column">
                        <CardBody className="py-4 flex-fill"> {/* Use flex-fill to make all cards equal height */}
                          <h6 className="text-primary text-uppercase">
                            Management Consultancy
                          </h6>
                          <p className="description mt-3">
                            Our excellent team can handle your Project & Product Management needs.
                          </p>
                          {<Button
                            className="mt-4"
                            color="success"                 
                            tag={Link}
                            to="/mgmtServices-page" // Directly specify the route here
                        > 
                            Details
                          </Button> }
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <Card className="card-lift--hover shadow border-0 d-flex flex-column">
                        <CardBody className="py-4 flex-fill">
                          <h6 className="text-primary text-uppercase">
                            System on Chip Lifecycle
                          </h6>
                          <p className="description mt-3">
                            We cover activities in preSi & postSi validations, compliances & certification.
                          </p>
                          {/* <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <Card className="card-lift--hover shadow border-0 d-flex flex-column">
                        <CardBody className="py-4 flex-fill">
                          <h6 className="text-success text-uppercase">
                            Embedded & Firmware
                          </h6>
                          <p className="description mt-3">
                            End to end Product development. FW Design, Porting, Development & Optimizations.
                          </p>
                          { <Button
                            className="mt-4"
                            color="success"                 
                            tag={Link}
                            to="/FWLinuxServices-page" // Directly specify the route here
                        > 
                            Details
                          </Button> }
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3" md="6" sm="12">
                      <Card className="card-lift--hover shadow border-0 d-flex flex-column">
                        <CardBody className="py-4 flex-fill">
                          <h6 className="text-warning text-uppercase">
                            Data Analysis Services
                          </h6>
                          <p className="description mt-3">
                            We provide meaningful patterns from bulk raw manufacturing data.
                          </p>
                          {/* <Button
                            className="mt-4"
                            color="warning"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button> */}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center ">
              <h3 className="text-primary"> Fill the below form to contact </h3> 
              </Row> 
              <Row className="justify-content-center ">              
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1"> We provide specialized in Design, Architectural services in semiconductor & embedded </h4>
                      <p className="mt-0">Your project is very important to us. Share your requirement/contact (Accepted from Signed-In users only) </p>
                      <form onSubmit={this.handleSubmit}>
                        <FormGroup className={classnames("mt-5", { focused: this.state.nameFocused })}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-user-run" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Your name"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onFocus={() => this.setState({ nameFocused: true })}
                              onBlur={() => this.setState({ nameFocused: false })}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className={classnames({ focused: this.state.emailFocused })}>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email address"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onFocus={() => this.setState({ emailFocused: true })}
                              onBlur={() => this.setState({ emailFocused: false })}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup className="mb-4">
                          <Input
                            className="form-control-alternative"
                            cols="180"			    
                            name="message"
                            placeholder="Type a message..."
                            type="textarea"
                            value={this.state.message}
                            onChange={this.handleChange}
                            rows="4"
                          />
                        </FormGroup>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="submit"
                          disabled={this.state.isPrjClk}  // Disable button already submitted
                        >
                          Send Message
                        </Button>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>           
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default DesignServices
