/******************************************************************************
* File Name: download.js
* Author   : Mukesh Kumar Basrani
* Summary  : Downloading File from firebase storage related code
* Created  : 30-May-2024
* Modified : 15-July-2024
******************************************************************************/
import React, {useState} from 'react';
import {storage } from '../firebaseCfg.js';
import {ref, getDownloadURL } from "firebase/storage";
import { useUserContext } from '../App';

const Download = () => {
  const [processedFileDnld, setProcessedFileDnld] = useState(false);
  const [invoiceFileDnld, setInvoiceFileDnld] = useState(false);
  
  const {
    processedFileUrl,
    setProcessedFileUrl,
    invoiceFileUrl,
    setInvoiceFileUrl,
    setDownloadSuccess,
    user,
  } = useUserContext();

  const handleDownload = async () => {
 //   console.log(' downloading file: 14');
    const storageRef = ref(storage);
    const fileRef = ref(storageRef, processedFileUrl);

    getDownloadURL(fileRef).then((url) => {
      // Create an anchor element
      const a = document.createElement('a');
      a.href = url;

      // Set the file name
      a.download = 'processed_file.csv';

      a.setAttribute('download',true);
      
      // Append the anchor element to the body
      document.body.appendChild(a);

      // Click the anchor element to trigger the download
      a.click();

      // Remove the anchor element from the body
      document.body.removeChild(a);
      setProcessedFileDnld(true)
      if(processedFileDnld && invoiceFileDnld) {
        setDownloadSuccess(true);
      }
    }).catch((error) => {
      console.error('Error downloading file:', error);
      // Handle any errors
    });
  };

  const handleDownloadInvoice = async () => {
 //   console.log(' downloading file: 14');
    const storageRef = ref(storage);
    const fileRef = ref(storageRef, invoiceFileUrl);

    getDownloadURL(fileRef).then((url) => {
      // Create an anchor element
      const a = document.createElement('a');
      a.href = url;

      // Set the file name
      a.download = 'Invoice_file.xlsx';

      a.setAttribute('download',true);
      
      // Append the anchor element to the body
      document.body.appendChild(a);

      // Click the anchor element to trigger the download
      a.click();

      // Remove the anchor element from the body
      document.body.removeChild(a);

      if(processedFileDnld && invoiceFileDnld) {
        setDownloadSuccess(true);
      }

    }).catch((error) => {
      console.error('Error downloading Invoice file:', error);
      // Handle any errors
    });
  };

  return (
    <div>
      <button onClick={handleDownload} 
      style={{ backgroundColor: 'blue', color: 'white', marginLeft:'30px', marginRight: '50px' }}>Download</button> 
      <button onClick={handleDownloadInvoice} 
      style={{ backgroundColor: 'green', color: 'white', marginLeft:'50px', marginRight: '50px' }} >Download Invoice</button>
    </div>
  );
};

export default Download;

