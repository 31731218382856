/******************************************************************************
* File Name: Upload.js
* Author   : Mukesh Kumar Basrani
* Summary  : Uploading File to firebase storage related code
* Created  : 30-May-2024
* Modified : 12-July-2024
******************************************************************************/
import React, {useState}from 'react';
import { getStorage, ref, uploadBytes} from "firebase/storage";
import { httpsCallable, getFunctions } from "firebase/functions";
import {useUserContext} from "../App"

const Upload = () => {
  const {
    file,
    setFile,
    uploadSuccess,
    setUploadSuccess,
    setProcessSuccess,
    processSuccess,
    setProcessedFileUrl,
    user,
    cgTermsAccepted,
    genTermsAccepted,
  } = useUserContext();

  const [isProcessing, setIsProcessing] = useState(false); // Track processing state

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }
    if(!cgTermsAccepted)
    {
      alert('Accept the CG Terms and conditions to proceed.');
      return;
    }    
    if(!genTermsAccepted)
    {
      alert('Accept the General Terms and conditions to proceed.');
      return;    
    }
    console.log("User Name and email ", {user})
    const storage = getStorage();
    const storageRef = ref(storage);
    const fileName = `${user.uid}.csv`;
    const filePathRef = ref(storageRef, 'uploads/' + fileName);

    //console.log('handleUpload: file, bucket', { fileName });

    uploadBytes(filePathRef, file)
      .then((snapshot) => {
        console.log('File uploaded successfully');
        setUploadSuccess(true);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
    });
  };

  const handleProcess = () => {
    if(!cgTermsAccepted)
    {
      alert('Accept the CG Terms and conditions to proceed.');
      return;
    }
    if(!genTermsAccepted)
    {
      alert('Accept the General Terms and conditions to proceed.');
      return;    
    }
    if (isProcessing) {
      return; // Prevent double-clicking
    }
    setIsProcessing(true); // Set processing state

    const functions = getFunctions();
    const storage = getStorage();
    const fileName = 'uploads/' + `${user.uid}.csv`;
    const bucket_name = storage._bucket.bucket;

    //console.log('handleProcess: file, bucket', { fileName });

    // Call Cloud Function to process the file
    const processFileGen = httpsCallable(functions, 'processFileGen', { method: 'POST' });
    const data = { fileUpName: fileName, bucketName: bucket_name, process_egn:'ICICI' };

    processFileGen(data)
      .then((result) => {
//        console.log('File processed result=', { result });
        if (result.data.success === true) {
          setProcessSuccess(true);
        } else {
          alert('Failed to process File');
        }
        // setProcessedFileUrl(result.data.download_link);
      })
      .catch((error) => {
        console.error('Error processing file:', error);
        if (error.error === 'Failed to process File') {
          alert('Failed to process File');
        } else if (error.error === 'text field missing in request data') {
          alert('Please provide the "text" field in your request.');
        } else if (error.error === 'internal server error') {
          alert('An internal server error occurred. Please try again later.');
        } else {
          alert('An unexpected error occurred.');
        }
    })
      .finally(() => {
      setIsProcessing(false); // Reset processing state
      });
  };

  return (
    <div>
      {!uploadSuccess ? (
       <div>
         <h1>Upload CSV File</h1>
          <input type="file" accept=".csv" onChange={handleFileChange} />
          <button onClick={handleUpload} disabled={uploadSuccess}>
            Upload
          </button>
       </div>
      ) : (
        <div>
          <h1>Process CSV File</h1>
          <button onClick={handleProcess} disabled={processSuccess || isProcessing}>
            Process
          </button>
          
        </div>
      )}
    </div>
  );
};

export default Upload;

// Function to generate UUID
/*function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
} */
