// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import {environ} from "./envCfg.js"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAp5gl-8xbfsc5HSTF6HhzawW2Gu0-eQgY",
  authDomain: "dendrixsemi.firebaseapp.com",
  projectId: "dendrixsemi",
  storageBucket: "dendrixsemi.appspot.com",
  messagingSenderId: "819521433104",
  appId: "1:819521433104:web:effb97cdfc2d9cd19a2495",
  measurementId: "G-E4DWSR09RY"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth();
const storage = getStorage(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

//const hostname = 'localhost';
const hostname = '';
// Connect to emulators if running locally
//if (process.env.NODE_ENV === 'development') {
if (hostname === 'localhost') {
  connectAuthEmulator(auth, "http://localhost:9099");
  connectStorageEmulator(storage, "localhost", 9199);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { app, auth, storage, functions, analytics };

