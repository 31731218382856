/******************************************************************************
* File Name: Auth.js
* Author   : Mukesh Kumar Basrani
* Summary  : Razorpay Front End handler and related code
* Created  : 30-May-2024
* Modified : 05-July-2024 
******************************************************************************/
import React, { useState, useEffect} from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
//import { GoogleAuthProvider } from 'firebase/auth';

//import {initializeApp} from "firebase/app"; // Correct import for firebase
//import { auth } from '../firebase'; // Import auth from your firebase.js file
import {signInWithPopup, GoogleAuthProvider, signOut} from 'firebase/auth'; // Import specific Firebase services for authentication

//import 'firebaseui/dist/firebaseui.css';
//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import 'firebase/auth';


const handleGoogleLogOut = (event) => {
  event.preventDefault();

  const auth = getAuth();
  signOut(auth).then((result) => {
   console.log("User signed out:");
  }).catch((error) => {
     console.error("User signed out:",error);
  });
 
}

const handleGoogleLogin = (event) => {
  event.preventDefault();

 const provider = new GoogleAuthProvider();
 const auth = getAuth();
//  firebase.auth().signInWithPopup(provider) 
 
 signInWithPopup(auth, provider) 
    .then((result) => {
      // User successfully signed in
      console.log("User signed in:", result.user);
    })
    .catch((error) => {
      // Handle errors
      console.error("Error signing in:", error);
    });
  }

const handleEmailRegister = ({name, email, password}) => {
/*    event.preventDefault();
    console.log(" handleEmailRegister  ", {event})
    console.log("Use handleEmailRegister" ,email, password); */

    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("Use handleEmailRegister", {email});
       // setRegistered(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(" handleAuthReg Error ", {errorCode}, {errorMessage});        
        // Handle errors
      });
  };

const handleEmailLogin = async ({email, password}) => {
 const auth = getAuth();
 try {
     const userCredential = await signInWithEmailAndPassword(auth, email, password);
     alert("You are Loged-in click OK and move to required page");
 } catch (error) {
     const errorCode = error.code;
     const errorMessage = error.message;
     console.log(" handleEmailLogin Error ", {errorCode}, {errorMessage});
     alert("Login failed", {errorMessage});
 }
}
  
const G_Auth = () => {
//  const [email, setEmail] = useState('');
//  const [password, setPassword] = useState('');
//});


/*  const handleAnonymousContinue = () => {
    // Implement anonymous login logic here
    console.log("Continuing anonymously");
    // Prevent setting authenticated state to true
    // Do not change the state here
  };
  

  const handleEmailRegister = (event) => {
    event.preventDefault();
    console.log(" handleEmailRegister  ", {event})
    const auth = getAuth();
          
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("Use handleEmailRegister" ,{email});        
       // setRegistered(true);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(" handleAuthReg Error " ,{errorCode} , {errorMessage});        
        // Handle errors
      });
  }; */

  return (
  <>
  </>
  );
 /* return (
    <div>
      <h1>Authentication</h1>
      <button onClick={handleGoogleLogin}>Register/Login with Google</button>
      <button onClick={handleAnonymousContinue}>Continue Anonymously</button>
      {//  Include your registration/login form }
      <form onSubmit={handleAuthReg}>
        <input 
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input 
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Register</button>
      </form>
    </div>
  );  */
};

export { handleGoogleLogin, handleGoogleLogOut, handleEmailRegister, handleEmailLogin};

export default G_Auth;


