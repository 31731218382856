/******************************************************************************
* File Name: firebaseAuth.js
* Author   : Mukesh Kumar Basrani
* Summary  : Firebase Auth and firebase UI display handling related code
* Created  : 30-May-2024
* Modified : 04-July-2024 
******************************************************************************/
//import React, { useEffect} from 'react';
//import { getAuth, createUserWithEmailAndPassword, connectAuthEmulator } from "firebase/auth";
import { GoogleAuthProvider, EmailAuthProvider } from 'firebase/auth';
import { auth } from '../firebaseCfg'; // Import auth from your firebase.js file

import 'firebaseui/dist/firebaseui.css';
//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import 'firebase/auth';
import * as firebaseui from 'firebaseui';

// FirebaseUI configuration
const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      
      return false;
    },
 /*   uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      //document.getElementById('loader').style.display = 'none';
      //document.getElementById('firebaseui-auth-container').style.display = 'none';
    } */
  },    
  signInFlow: 'popup', 
  //signInSuccessUrl: '/equity-page',
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID,
    // Add other authentication providers as needed...
  ],
  // Other FirebaseUI options...
};

// Initialize the FirebaseUI Widget using Firebase
const ui = new firebaseui.auth.AuthUI(auth); 

// Function to render FirebaseUI widget
export const startFirebaseUI = (elementId) => {
  ui.start(elementId, uiConfig);
}; 

const uiEmailConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.

      return false;
    },
 /*   uiShown: function() {
      // The widget is rendered.
      // Hide the loader.
      //document.getElementById('loader').style.display = 'none';
      //document.getElementById('firebaseui-auth-container').style.display = 'none';
    } */
  },    
  signInFlow: 'popup', 
  //signInSuccessUrl: '/equity-page',
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    // Add other authentication providers as needed...
  ],
  // Other FirebaseUI options...
};
export const startFirebaseUIEmail = (elementId) => {
  ui.start(elementId, uiEmailConfig);
}; 

