/******************************************************************************
* File Name: RzpPayment.js
* Author   : Mukesh Kumar Basrani
* Summary  : Razorpay Front End handler and related code
* Created  : 20-May-2024
* Modified : 12-July-2024
******************************************************************************/

import logo from "../logo.svg";
import axios from "axios";
//import React, {useContext } from 'react';
import {useUserContext} from '../App';

// import {rzpInit_URL, rzpVerfifyPayment_URL} from '../envCfg.js'
const rzpInit_URL = process.env.REACT_APP_RZP_INIT_URL;
const rzpVerifyPayment_URL = process.env.REACT_APP_RZP_VERIFY_URL;
const rzpKeyId = process.env.REACT_APP_RZP_KEY_ID;


const RzpPayment = (user) => {
 /* const {
    setPaymentSuccess,
    user,
  } = useUserContext(); */
  
  return new Promise((resolve, reject) => {
  const handleRzpPayment = async () => {


  let verification_status = 'failure';
  console.log("RzpPayment Init:31");


     try {     
     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');

      if (!res) {
        alert('Razorpay SDK failed to load. Are you online?');
        console.log('Error on loading 24', {res})
        return;
      } 
//      console.log('loading 42', {rzpInit_URL}, { rzpVerifyPayment_URL})
      console.log('loading 43', {res}, { rzpKeyId: rzpKeyId.slice(0, 12) })

      const response = await axios.post(rzpInit_URL);
      //const name = user.displayName || "";

      //const response = await axios.post('http://127.0.0.1:5001/myfirebaseuploademul/us-central1/RzpInitiatePayment');      // alert(response.data.orderId);
      //console.info(' on payment 31: response : Key', {response}, {rzpKeyId})
      const { key,amount, currency,orderId } = response.data;
      //console.info('Rzp on payment 33 order for user', {orderId})
      const options = {
        key: rzpKeyId, // Enter the Key ID generated from the Dashboard
        amount: '500', //amount.toString(),
        currency: 'INR',
        name: 'DendrixSemi MicroTech.',
        description: 'Transaction',
        image: { logo },
        order_id: orderId, //order_id,
        handler: async function (response) {
          const data = {           
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };


          const paymentResult = await axios.post(rzpVerifyPayment_URL, data);
          //const paymentResult = await axios.post('http://127.0.0.1:5001/myfirebaseuploademul/us-central1/RzpVerifyPayment', data);	  
          //console.info('handleRzpPayment 54', {paymentResult})
          //alert(paymentResult.data.msg);
          // Return verification status along with other values
          verification_status  = paymentResult.status == 200 ? 'success':'failure';
          //console.info('handleRzpPayment 58', {verification_status})           
          // resolve promise with verifications
          resolve({
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            verificationStatus: verification_status,
          });
        },
        prefill: {
          name: user.displayName,
          email: user.email,
          contact: '9999999999',
        },
        notes: {
          address: 'DendrixSemi MicroTech Pvt. Ltd.',
        },
        theme: {
          color: '#61dafb',
        },
      };
      //console.log("RzpPayment Init:92 ",user.displayName);
      console.log("RzpPayment Init:88",user.displayName, user.email);
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      alert('Error processing payment. Please try again later.');
    }
  }
  
  
  function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });

  };

   handleRzpPayment(user)
      .catch( error => reject(error)); 
  })

};

export default RzpPayment;
