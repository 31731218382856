/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
//import { Component } from "react";

//import { useEffect, useState } from 'react';
//import {auth } from '../../firebaseCfg';
import {G_Auth } from '../../components/G_Auth.js'
import {handleGoogleLogin, handleGoogleLogOut,handleEmailLogin} from '../../components/G_Auth.js'
//import {} from G_Auth
// Import specific Firebase services
//import 'firebase/auth'; // for authentication

//import 'firebaseui/dist/firebaseui.css';
//import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

// reactstrap ../../components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Link } from "react-router-dom";

// core ../../components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";

class Login extends React.Component {
constructor(props) {
super(props);
this.state = {
    email: '',
    password: ''
  };

  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
      // Add event listener to the button
    const g_button = document.getElementById("igoogle-login-button");
    g_button.addEventListener("click", handleGoogleLogin);

  }
  
    /*  ---- Move to Components later --------- */
    handleChange = (e) => {
      this.setState({ [e.target.name]: e.target.value });
    };   
    
    handleCheckboxChange = (e) => {
      const { name, checked } = e.target;
      this.setState({
        [name]: checked
      }, () => {
//             // Log updated state
      });
    };
    handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
//    console.log('email, password ', email, password )
       handleEmailLogin({email, password})

 
  };

  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="6">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <small>Sign in with</small>
                      </div>
                      <div className="btn-wrapper text-center">
                        <Button
                          className="btn-neutral btn-icon"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={
                                require("../../assets/img/icons/common/github.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text">Github</span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon ml-1"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          id="igoogle-login-button"
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={
                                require("../../assets/img/icons/common/google.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign in with credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
			    
                            <Input
                              placeholder="Email"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              value={this.state.password}
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor=" customCheckLogin"
                          >
                            <span>Remember me</span>
                          </label>
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="submit"
                          >
                            Sign in
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        {/*<small>Forgot password?</small> */}
                      </a>
                    </Col>

                    <Col className="text-right" xs="6">
                    <Link to="/register-page" className="text-light"> 
                    {/* Use Link component */}
                    <small>Create new account</small>
                    </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}


//export default Login;
/*
class Login extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  
//const Login = () => {
  const [isSignedIn, setIsSignedIn] = useState(false);

  useEffect(() => {
    const unregisterAuthObserver = auth().onAuthStateChanged(user => { // Correct usage of auth
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure to un-register Firebase observers when the component unmounts.
  }, []);

  // FirebaseUI Config
  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      auth.EmailAuthProvider.PROVIDER_ID,
      auth.GoogleAuthProvider.PROVIDER_ID,
      // Add more providers as needed
    ],
  };

render() {
  return (
    <div>
      {isSignedIn ? (
        <div>
          <p>Welcome {auth().currentUser.displayName}! You are now signed-in!</p>
          <button onClick={() => auth().signOut()}>Sign-out</button>
        </div>
      ) : (
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
      )}
    </div>
  );
  }
};
*/
/*

class Login extends Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  constructor(props) {
    super(props);
    this.state = {
      isSignedIn: false
    };
  }

  componentDidMount() {
    this.unregisterAuthObserver = auth().onAuthStateChanged(user => {
      this.setState({ isSignedIn: !!user });
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    const { isSignedIn } = this.state;

    const uiConfig = {
      signInFlow: 'popup',
      signInSuccessUrl: '/login-page',
      signInOptions: [
        auth.EmailAuthProvider.PROVIDER_ID,
        auth.GoogleAuthProvider.PROVIDER_ID,
        // Add more providers as needed
      ],
    };

    return (
      <div ref="main">
        {isSignedIn ? (
          <div>
            <p>Welcome {auth().currentUser.displayName}! You are now signed-in!</p>
            <button onClick={() => auth().signOut()}>Sign-out</button>
          </div>
        ) : (
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
        )}
      </div>
    );
  }
}
*/
export default Login
