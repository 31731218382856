/******************************************************************************
* File Name: PaymentWrp.js
* Author   : Mukesh Kumar Basrani
* Summary  : Razorpay Front End handler and related code
* Created  : 30-May-2024
* Modified : 12-July-2024
******************************************************************************/
import React, {useState} from 'react';
//import { httpsCallable, getFunctions } from "firebase/functions";
import RzpPayment from './RzpPayment'
import axios from 'axios'
import { useUserContext } from '../App';
//import { UserContext } from '../App'; 

//import UserContext from '../App';

// import {paymentBEPy_URL} from '../envCfg.js' 
const paymentBEPy_URL=process.env.REACT_APP_PAYMENT_BEPY_URL

//const {handleRzpPayment, loadScript} = RzpPayment();
let result;
const PaymentWrp = () => {
  const {
    setPaymentSuccess,
    user,
  } = useUserContext();
  const [isPayClk, setIsPayClk] = useState(false); // Track Continue state

  const handlePayment = async () => {
    //console.log("handlePayment Init:31",{user.displayName});
    if (isPayClk) {
      return; // Prevent double-clicking
    }
    setIsPayClk(true); // Set processing state
    console.log("handlePayment Init 36:", user.displayName);
      // Await the result of handleRzpPayment
      result = await RzpPayment(user)
          .then(paymentData => {
              ///console.log("Payment successful 26:", {result});                      
//              console.log("handlePayment BE:31", {paymentData});
              if (paymentData.verificationStatus == "success")
              {  
                  setPaymentSuccess(true);
              }
              else 
              {
                  alert("Your payment failed Try again")
              }
          })
          .catch( error => {
              console.error("Payment failed: result is undefined");
          });
  };

  return (

    <div>
      <h1>Payment</h1>
      <h2> Wait for 2 mins <u> after approving payment  </u> on gateway screen, for success screen to appear) </h2>
      { <button onClick = {() => handlePayment()} disabled={isPayClk} >Pay to Download</button>  }
    </div>
  );
};

const PaymentWrpCnf = () => {
  const {
    setPaymentCnfSuccess,
    setProcessedFileUrl,
    setInvoiceFileUrl,
    user,
  } = useUserContext();
  const [isContinueClk, setIsContinueClk] = useState(false); // Track Continue state
  
  const handlePaymentCnf = async () => {
    if (isContinueClk) {
      return; // Prevent double-clicking
    }
    setIsContinueClk(true); // Set processing state
    
    try {
      console.log("handlePaymentCnf Init 73:", user.email);
        // Make the Axios POST request

        const response = await axios.post(paymentBEPy_URL,
                                          {'Test': 'test1','userName': user.displayName, 'email': user.email, },
                                          {  headers: { 'Content-Type': 'application/json' }
                                          });

//        console.info('Payment response:67 : success :', response , response.data.success);

        if (response.data.success) {
          setProcessedFileUrl(response.data.download_link);
//          console.info('Info Payment response success:', response.data.download_link);
          setInvoiceFileUrl(response.data.invoice_link);
          console.info('Info Payment response success: 86');
          setPaymentCnfSuccess(true);
        } else {
          console.error('An error occurred during payment confirmation');
          setPaymentCnfSuccess(false);
        } 
      } catch (error) {
      console.error("Payment failed:87", error);
      setPaymentCnfSuccess(false);      
      // Handle the payment failure
    }

  };

  return (
 
    <div>
      <h1> Payment Made </h1>
      <button onClick={handlePaymentCnf} disabled={isContinueClk} >Click to continue</button>
    </div>
  
  );
};
export {PaymentWrp , PaymentWrpCnf};
