import React, { useState, useContext, useRef } from 'react';
import  CgTermsContext, { useCgTermsContext }  from '../termsContext'; // Assuming you have a context defined
import hostingCfg from '../hostingcfg/hostingcfg.json'; // Assuming hosting configuration
import {
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'; // Example imports for links
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook from React Router
import DemoNavbar from '../components/Navbars/DemoNavbar'; // Example import for Navbar
import SimpleFooter from '../components/Footers/SimpleFooter'; // Example import for Footer

const TermsConditions = () => {
  const [localState_GenTermsAccepted, setLocalState_GenTermsAccepted] = useState(false); // Example state
  const navigate = useNavigate(); // Hook to navigate programmatically
  const mainRef = useRef(null); // Ref for the main element

  const handleGenCheckboxChange = (e) => {
    const { checked } = e.target;
    setLocalState_GenTermsAccepted(checked);
  };

  const handleGoBack = () => {
    navigate(-1); // Go back to previous page
  };

  const company = hostingCfg.Company; // Example company variable

  return (
    <CgTermsContext.Provider value={{ genTermsAccepted: localState_GenTermsAccepted }}>
      <>
        <DemoNavbar />
        <main ref={mainRef}>
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-teal">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <div> 
                <span>                  
                  <Nav>
                    <NavItem>
                      <NavLink
                        href={`${company.website}/General_Terms_and_cond_website.html`}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        
                        <h3> General Terms and Conditions (Click to read) </h3>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </span>

              <p></p>
              <Nav>
                <NavItem>
                  <NavLink
                    href={`${company.website}/Privacy_policy.htm`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <h4 >  Privacy Policy (Click to read) </h4>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </section>
          <button onClick={handleGoBack}>Go Back</button>
        </main>
        <SimpleFooter />
      </>
    </CgTermsContext.Provider>
  );
};

export default TermsConditions;

