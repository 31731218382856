/*!

=========================================================
* Argon Design System React - v1.1.2
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import { Container, Row, Col } from "reactstrap";
import './logo.css';
import hostingCfg from "../../hostingcfg/hostingcfg.json";
import './hero.css'; // Ensure this CSS file is created and modified

class Hero extends React.Component {
  render() {
    const company = hostingCfg.Company;
    return (
      <>
        <div className="position-relative">
          <section className="section section-hero section-shaped pb-0 style={{ minHeight: '100vh' }}">
            <div className="shape shape-style-1 shape-default" style={{ 
              background: 'linear-gradient' // Gradient from purple to darker purple
            }}>
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-50" />              
            </div>

            <Container fluid className="py-0"> {/* Use fluid to fill the entire width */}
              <Row className="row-grid justify-content-start align-items-center"> {/* Align items as needed */}
                <Col xl="7" lg="7" md="8" sm="12" className="text-left"> {/* Ensure left alignment */}
                  <h1 className="display-3 text-white">                
                    DENDRIXSEMI MICROTECH
                  </h1> 
                  <p className="display-5 text-white" style={{ paddingTop: '10px' }}>
                    is at the forefront of technological innovation, specializing in cutting-edge semiconductor technologies, embedded products, communication systems, and AI-driven solutions. <br />
                    We are dedicated to addressing the needs of our customers by delivering innovative, cost-effective, timely products and solutions that not only meet but exceed expectations.
                  </p>
                </Col>

                <Col className="order-lg-2" sm="12" md="4" lg="5" xl="5">
                  <div className="card-profile-image text-center">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/Designer_2.jpeg")}
                      className="responsive-image"
                      style={{ objectFit: 'cover', height: '100%' }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>


          </section>
        </div>
      </>
    );
  }
}

export default Hero;


