/*** User Context for Terms of agreement selection etc ***/

import React, { useState, createContext, useContext } from 'react';
const CgTermsContext = createContext(null);

export const useCgTermsContext = () => {
  return useContext(CgTermsContext); 
};
//export const useCgTermsContext = () => useContext(CgTermsContext);
export default CgTermsContext;

