/*!



=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// reactstrap ../../components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import {handleGoogleLogin, handleGoogleLogOut, handleEmailRegister} from '../../components/G_Auth.js'

// core ../../components
import DemoNavbar from "../../components/Navbars/DemoNavbar.js";
import SimpleFooter from "../../components/Footers/SimpleFooter.js";
import App from "../../App.js"
import {TermsButton, CGTermsButton} from "../../termsButton"

import  CgTermsContext  from '../../termsContext'; 


class Equity extends React.Component {

constructor(props) {
super(props);
  this.state = {
    name: '',
    email: '',
    password: '',
    localState_cgTermsAccepted:false, 
    localState_genTermsAccepted: false, // State for General Terms and Conditions 
 };
 
  this.cgTermsAccepted = false; // Define cgTermsAccepted here
  this.setCgTermsAccepted = (value) => {
    this.cgTermsAccepted = value; // Update cgTermsAccepted
    };

    this.genTermsAccepted = false; // Define genTermsAccepted here
    this.setGenTermsAccepted = (value) => {
      this.genTermsAccepted = value; // Update genTermsAccepted
    };

  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    const g_button = document.getElementById("igoogle-eq-register-button");
    g_button.addEventListener("click", handleGoogleLogin);  
    }
    
       
  /*  ---- Move to Components later --------- */
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
    
  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, password } = this.state;
    handleEmailRegister({name, email, password})
  };
  

  handleCgCheckboxChange = (e) => {
    const { name, checked } = e.target;
    this.setState({
      localState_cgTermsAccepted: checked 
     }, () => {
      // Update CgTermsContext
      // const { setCgTermsAccepted } = useCgTermsContext();     
      this.setCgTermsAccepted(checked); // Update the context value       
       // console.log('handleCgCheckboxChange():cgTrmsAccepted 89 = ', this.cgTermsAccepted);              
     });
  }     
  handleGenCheckboxChange = (e) => {
    const { checked } = e.target;
    this.setState({
      localState_genTermsAccepted: checked
    }, () => {
      this.setGenTermsAccepted(checked);
    });
  };  
  /*  ---- Move to Components later --------- */
  //        <CgTermsContext.Provider value={{ cgTermsAccepted: localState_cgTermsAccepted}}>  
  render() {
    const {localState_cgTermsAccepted, localState_genTermsAccepted} = this.state;  

    return (
      <CgTermsContext.Provider value={{ 
        cgTermsAccepted: localState_cgTermsAccepted,
        genTermsAccepted: localState_genTermsAccepted,
      }}>
      <>
        <DemoNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="6">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-white pb-5">
                      <div className="text-muted text-center mb-3">
                        <small>Sign up with</small>
                      </div>
                      <div className="text-center">
                        <Button
                          className="btn-neutral btn-icon mr-4"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={
                                require("../../assets/img/icons/common/github.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text">Github</span>
                        </Button>
                        <Button
                          className="btn-neutral btn-icon ml-1"
                          color="default"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          id="igoogle-eq-register-button"                        
			  >
                          <span className="btn-inner--icon mr-1">
                            <img
                              alt="..."
                              src={
                                require("../../assets/img/icons/common/google.svg")
                                  .default
                              }
                            />
                          </span>
                          <span className="btn-inner--text">Google</span>
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4">
                        <small>Or sign up with credentials</small>
                      </div>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-hat-3" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Name"
                              type="text"
                              name="name"
                              value={this.state.name}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative mb-3">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-email-83" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Email"
                              type="email"
                              name="email"
                              value={this.state.email}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              placeholder="Password"
                              type="password"
                              name="password"
                              value={this.state.password}
                              autoComplete="off"
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="text-muted font-italic">
                          <small>
                            password strength:{" "}
                            <span className="text-success font-weight-700">                              
                              --
                            </span>
                          </small>
                        </div>
                        <Row className="my-4">
                          <Col xs="12">
                            <div className="custom-control custom-control-alternative custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="customCheckRegister"
                                type="checkbox"
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customCheckRegister"
                              >
                              </label>
                            </div>
                          </Col>
                        </Row>
                        <div className="text-center">
                          <Button
                            className="mt-4"
                            color="primary"
                            type="submit"
                          >
                            Create account
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
                      <div className="custom-control custom-control-alternative custom-checkbox">
        <TermsButton/>                      
                        <input
                          className="custom-control-input"
                          id="GenCustomCheckRegister"
                          type="checkbox"
                          name="genTermsAccepted"
                          checked={localState_genTermsAccepted}
                          onChange={this.handleGenCheckboxChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="GenCustomCheckRegister"
                        >
                          <span>
                            I agree with the { }
                            <a
                              href="/terms-conditions-page" // Direct link to general-terms-conditions-page
                              onClick={(e) => {
                                e.preventDefault();
                                // Navigate to general-terms-conditions-page
                                window.location.href = '/terms-conditions-page';
                              }}
                            >
                              General T&C and Privacy T&C
                            </a>
                          </span>
                        </label>
                      </div>
        <CGTermsButton/>
          <div className="custom-control custom-control-alternative custom-checkbox">
             <input
              className="custom-control-input"
              id="CGcustomCheckRegister"
              type="checkbox"
              name="cgTermsAccepted"
              checked={localState_cgTermsAccepted}
              onChange={this.handleCgCheckboxChange}               
              />
              <label
                className="custom-control-label"
                htmlFor="CGcustomCheckRegister"
               >
                 <span>
                   I agree with the{" "}
                   <a onClick={(e) => {e.preventDefault();}}>
                     CG Service Terms and Conditions
                    </a>
               </span>                            
             </label>
        </div>
        </main>
        <App/>
        <SimpleFooter />
      </>
      </CgTermsContext.Provider>
    );
  }
} 


export default Equity;
