// TermsPopup.js

import React, { useState, useEffect } from 'react';
import "./popup.css";

import { useNavigate } from 'react-router-dom';

const TermsPopup = ({ onClose }) => {
  const navigate = useNavigate();

  const handleNavigateToTerms = () => {
    navigate('/terms-conditions-page'); // Navigate to the terms-conditions-page
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div>
          <p>
            Placeholder content for the terms and conditions. You can add text or other elements here.
          </p>
        </div>
        <button className="terms-button" onClick={handleNavigateToTerms}>
          View Terms and Conditions
        </button>
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default TermsPopup;

/*
const TermsPopup = ({ onClose }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the file
    fetch(`${process.env.PUBLIC_URL}/General_Terms_and_cond_website.html`)
      .then(response => response.text())
      .then(data => setHtmlContent(data))
      .catch(error => console.error('Error fetching HTML:', error));
  }, []);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> 
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};
*/
const CGTermsPopup = ({ onClose }) => {
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content from the file
    fetch(`${process.env.PUBLIC_URL}/CG_Terms_of_service.html`)
      .then(response => response.text())
      .then(data => setHtmlContent(data))
      .catch(error => console.error('Error fetching HTML:', error));
  }, []);

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> 
        <button className="close-button" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export {TermsPopup, CGTermsPopup}

//export default TermsPopup;


